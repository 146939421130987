import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import store from '../../redux/store';
import { SET_FACILITATOR_SESSION_ID } from '../../redux/redux-constants';
import { getFacilitatorState } from '../../services/facilitatorApiService';
import {
  Button,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import _ from 'lodash';
import logoMark from '../../assets/img/logo-mark.png';
import useConfig from '../../hooks/use-config';
import { startCase } from '../../utils';

function Roster({ selectedSessionId, companies, allSessionIds }) {
  const { tFallback } = useConfig();
  const [isSessionMenuOpen, toggleSessionMenuOpen] = useState(false);

  const handleSelectSession = (sessionId) => {
    store.dispatch({
      type: SET_FACILITATOR_SESSION_ID,
      payload: sessionId,
    });
    getFacilitatorState(sessionId);
  };

  useEffect(() => {
    if (selectedSessionId) {
      getFacilitatorState(selectedSessionId);
    }
  }, [selectedSessionId]);

  return (
    <Container fluid>
      <Row className="bg-white border border-light-gray rounded-lg mt-4 mx-2 p-3">
        <Col>
          <h3 className="mb-0 font-weight-light">
            <a className="mr-3" href="/">
              <img
                src={logoMark}
                alt={tFallback(
                  'LABEL_MANAGEMENT_CHALLENGE',
                  'Management Challenge'
                )}
                width="32px"
              />
            </a>
            {tFallback('LABEL_TEAM_ROSTER_FOR', 'Team Roster for')}{' '}
            {startCase(selectedSessionId)}
          </h3>
        </Col>
        <Col
          className={`d-flex align-items-center ${
            allSessionIds.length > 1
              ? 'justify-content-center'
              : 'justify-content-end'
          }`}
        >
          <a href="/" target="_blank" rel="noreferrer noopener">
            <h3 className="mb-0 text-info">{window.location.hostname}</h3>
          </a>
        </Col>
        {allSessionIds.length > 1 && (
          <Col className="d-flex justify-content-end align-items-center">
            <Dropdown
              isOpen={isSessionMenuOpen}
              toggle={() => toggleSessionMenuOpen(!isSessionMenuOpen)}
            >
              <DropdownToggle caret color="gray">
                {selectedSessionId ? (
                  <span className="text-muted mr-4">
                    {tFallback('LABEL_CURRENT_SESSION', 'Current Session')}:{' '}
                    <span className="text-info">{selectedSessionId}</span>
                  </span>
                ) : (
                  tFallback('LABEL_SELECT_SESSION', 'Select Session')
                )}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                {selectedSessionId && (
                  <>
                    <DropdownItem header>
                      {tFallback('LABEL_CURRENT_SESSION', 'Current Session')}:{' '}
                      <span className="text-info">{selectedSessionId}</span>
                    </DropdownItem>
                    <DropdownItem divider />
                  </>
                )}
                {((!selectedSessionId && allSessionIds.length > 0) ||
                  (selectedSessionId && allSessionIds.length > 1)) && (
                  <>
                    <DropdownItem header>
                      {tFallback('LABEL_SWITCH_SESSION', 'Switch Session')}
                    </DropdownItem>
                    {_.reject(
                      allSessionIds,
                      (sId) => sId === selectedSessionId
                    ).map((sId) => (
                      <DropdownItem
                        key={sId}
                        onClick={() => handleSelectSession(sId)}
                      >
                        {sId}
                      </DropdownItem>
                    ))}
                    <DropdownItem divider />
                  </>
                )}
              </DropdownMenu>
            </Dropdown>
          </Col>
        )}
      </Row>
      <Row className="pt-3 pb-0 px-2">
        {allSessionIds && allSessionIds.length === 0 && (
          <Col>
            <Card>
              <CardHeader className="bg-dark">
                <CardTitle className="text-white text-lg mb-0">
                  {tFallback('LABEL_NO_SESSIONS_EXIST', 'No Sessions Exist')}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <h3>
                  {tFallback(
                    'LABEL_SET_UP_AT_LEAST_ONE_SESSION_TO_CONTINUE',
                    'Set up at least one session to continue.'
                  )}
                </h3>
                <Button
                  color="info"
                  outline
                  href="/facilitator"
                  className="mr-3"
                >
                  {tFallback(
                    'LABEL_GO_TO_FACILITATOR_DASHBOARD',
                    'Go to Facilitator Dashboard'
                  )}
                </Button>
              </CardBody>
            </Card>
          </Col>
        )}
        {companies.map((c) => (
          <Col xs={12} md={6} lg={4} xl={3} key={c.name}>
            <Card>
              <CardHeader className="bg-dark" title={c.name}>
                <CardTitle className="text-white text-lg mb-0">
                  {c.name}
                </CardTitle>
              </CardHeader>
              <CardBody className="pl-3 pr-2 pb-1 pt-2">
                {c.players.map((p) => (
                  <div
                    key={p.name}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <h2>{p.name}</h2>
                    <span className="text-sm">{p.roleName}</span>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="bg-white border border-light-gray rounded-lg mx-2 p-3">
        <Col className="d-flex align-items-center">
          <Button color="info" outline href="/facilitator" className="mr-3">
            {tFallback(
              'LABEL_GO_TO_FACILITATOR_DASHBOARD',
              'Go to Facilitator Dashboard'
            )}
          </Button>
          <Button color="info" outline href="/sign-in">
            {tFallback('LABEL_GO_TO_LOGIN_SCREEN', 'Go to Login Screen')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default connect((store) => ({
  companies: store.facilitatorState.companies,
  selectedSessionId: store.facilitatorState.selectedSessionId,
  allSessionIds: _.reject(
    store.facilitatorState.allSessionIds,
    (sessionId) => sessionId.indexOf('tutorial') > -1
  ),
}))(Roster);
