import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import store from './redux/store/index';
import Routes from './routes/Routes';
import BlackoutScreen from "./components/BlackoutScreen";
import {DictionaryProvider} from "./context/dictionary-context";

const App = (props) => {
  return (
    <DictionaryProvider>
      <Provider store={store}>
        <BlackoutScreen />
        <Routes />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
          removeOnHover={false}
        />
      </Provider>
    </DictionaryProvider>
  );
};

export default App;
